<template>
  <div
    class="FormProgress"
    :class="[
      showPercentage && form && form.progressBarLabelPosition,
      ..._elSel('ProgressBarBackground'),
    ]"
  >
    <div class="bar" :class="_elSel('ProgressBarFilled')" :style="{ width: `${percent}%` }" />
    <div v-if="showPercentage" class="progress" :class="{ altColor: percentThreshhold }">
      {{ percentDisplayText }}
    </div>

    <Icon
      v-if="hasData"
      class="reset"
      :class="`percent-${percent}`"
      icon="fa-solid:redo"
      @click.native="reset"
    />
  </div>
</template>

<script>
import Icon from './Icon.vue'

export default {
  name: 'FormProgress',
  inject: {
    _elSel: { default: () => () => [] },
  },
  components: {
    Icon,
  },
  props: {
    pages: Array,
    component: Object,
    userData: Object,
    showPercentage: Boolean,
    form: Object,
    currentPageIndex: Number,
  },
  computed: {
    percentThreshhold() {
      return this.percent > 10
    },
    percentageType() {
      const componentVal = this.component && this.component.percentageType
      if (componentVal === undefined) {
        const formVal = this.form && this.form.percentageType
        return formVal === undefined ? 'percent-complete' : formVal
      }
      return componentVal
    },
    startAtOne() {
      const componentVal = this.component && this.component.progressBarStartAtOne
      if (componentVal === undefined) {
        const formVal = this.form && this.form.progressBarStartAtOne
        return formVal
      }
      return componentVal
    },
    completeText() {
      const componentVal = this.component && this.component.progressBarCompleteText
      if (componentVal === undefined) {
        const formVal = this.form && this.form.progressBarCompleteText
        return formVal === undefined ? '' : formVal
      }
      return componentVal
    },
    percentDisplayText() {
      if (!this.form) return ''
      switch (this.percentageType) {
        case 'fractional': {
          if (this.startAtOne) {
            const pageIndex = this.currentPageIndex + 1
            return pageIndex === this.pages.length
              ? this.completeText || ''
              : `${pageIndex} / ${this.pages.length > 1 ? this.pages.length - 1 : 1}`
          }
          return `${this.currentPageIndex} / ${this.pages.length > 1 ? this.pages.length - 1 : 1}`
        }

        default: {
          return `${this.percent}% complete`
        }
      }
    },
    percent() {
      if (!this.form) return 0
      const currentPageIndex =
        this.percentageType === 'fractional' && this.startAtOne
          ? this.currentPageIndex + 1
          : this.currentPageIndex
      const currentTotalPages = this.pages.length > 1 ? this.pages.length - 1 : 1

      return Math.min(Math.round((100 * currentPageIndex) / currentTotalPages), 100)
    },
    hasData() {
      return this.userData && Object.keys(this.userData).length > 1
    },
  },
  methods: {
    reset() {
      this.$emit('reset')
    },
  },
}
</script>
